import { Avatar } from "@mui/material";
import { ReporterData } from "../../types/report";
import "./styles.scss";

type Props = ReporterData;

const Reporter = ({ name, reporterName, photo, isShort }: Props) => {
  const size = isShort ? 40 : 54;

  return (
    <div className="reporter">
      <div className="reporter-avatar" style={{ borderColor: isShort ? "#596DBB" : "#f38701" }}>
        <Avatar
          sx={{ width: size, height: size }}
          src={`${process.env.PUBLIC_URL}/assets/photos/${photo}`}
        />
      </div>
      <div className="reporter-info">
        <div className="reporter-info__name">{reporterName}</div>
        {!isShort ? <div className="reporter-info__company">{name}</div> : null}
      </div>
    </div>
  );
};

export default Reporter;
