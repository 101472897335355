import "./styles.scss";
import HeaderTag from "../Tags/HeaderTag";
import { Button } from "@mui/material";
import { NavHashLink } from "react-router-hash-link";

const tags = [
  "AI",
  "Архитектура",
  "DB",
  "Процессы",
  "Управление",
  "Мотивация",
  "Mobile",
  "Web",
];

const Header = () => {
  return (
    <div className="header">
      <div className="container">
        <div className="header-wrapper">
          <span className="header__title">Yappi <br /> Days Conf</span>
          <span className="header__subtitle">
            Крупнейшая IT-конференция Ярославля для нетворкинга и обмена опытом
          </span>
        </div>
        <div className="header-info">
          <div className="header-info__title">23 октября, Ярославль</div>
          <div className="header-info__tags">
            {tags.map((tag, index) => (
              <HeaderTag key={`header-tag-${index}`} text={tag} />
            ))}
          </div>
          <div className="header-info__buttons">
            <NavHashLink to="/#schedule" className="link">
              <Button
                className="header-info__buttons-schedule"
              >
                Расписание
              </Button>
            </NavHashLink>
            <NavHashLink to="/#ticket" className="link">
              <Button
                className="header-info__buttons-buy"
              >
                Купить билет
              </Button>
            </NavHashLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
