import Details from "../components/Details/Details";
import Footer from "../components/Footer/Footer";
import Menu from "../components/Menu/Menu";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { mockedData, reportsData } from "../data/mockData";
import { ReportWithTime } from "../types/report";
import { hallsName } from "../constants/hall";

const DetailPage = () => {
  const { id } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const lastHash = useRef("");
  const [show, setShow] = useState(true);
  const [position, setPosition] = useState(0);
  const [report, setReport] = useState<ReportWithTime>();

  useEffect(() => {
    if (id) {
      const selectedReport = reportsData.find((item) => item.id === +id);
      const schedule = mockedData.find(item => item.reports && item.reports.includes(+id));
      const reportIndex = schedule?.reports?.findIndex(reportId => reportId === +id) as number;

      setReport({ ...selectedReport, time: schedule?.time, hall: hallsName[reportIndex], isShort: schedule?.isShort } as ReportWithTime);
    }
  }, [id]);

  useEffect(() => {
    if (ref && ref.current) {
      setPosition(ref.current.scrollTop);
      ref.current.addEventListener("scroll", () => {
        const { scrollTop } = ref.current as HTMLDivElement;
        setShow(position > scrollTop);
        setPosition(scrollTop);
      });
    }
  }, [position]);

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  return (
    <div className="details-page">
      <Menu show={show} />
      {report ? <Details report={report} /> : null}
      <Footer />
    </div>
  );
};

export default DetailPage;
