import { useNavigate } from "react-router-dom";
import { tagsMap } from "../../constants/tags";
import { Report } from "../../types/report";
import ReportTag from "../Tags/ReportTag";
import Reporter from "./Reporter";
import "./styles.scss";
import classNames from "classnames";

type Props = Report;

const ReportCard = ({
  title,
  reporterName,
  company,
  tags,
  photo,
  id,
  isShort
}: Props) => {
  const navigate = useNavigate();

  const onTitleClick = () => {
    navigate(`/detail/${id}`);
  };

  return (
    <div
      className={classNames("report-card", {
        short: isShort
      })}
      onClick={onTitleClick}
    >
      <div className="report-card-container">
        <div className="report-card__title">
          {title}
        </div>
        <div className="report-card__info">
          <div className="report-card__reporter">
            <Reporter {...company} photo={photo} reporterName={reporterName} isShort={isShort} />
          </div>
          {!isShort ?
            (
              <div className="report-card__tags">
                {tags.map((tag, index) => {
                  const tagData = tagsMap.get(tag);
                  return <ReportTag key={`report-tag-${index}`} {...tagData} />;
                })}
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
