import DetailPage from "./pages/DetailPage";
import HomePage from "./pages/HomePage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import "./App.scss";

function App() {
  useEffect(() => {
    const timePadWidget = document.getElementById("timepad-widget");
    if (timePadWidget == null) {
      const script = document.createElement("script");
      script.src = "https://timepad.ru/js/tpwf/loader/min/loader.js";
      script.id = "timepad-widget";
      script.async = true;
      script.defer = true;
      script.setAttribute("data-timepad-customized", "29906");
      script.setAttribute("data-timepad-widget-v2", "event_register");
      script.setAttribute("charset", "UTF-8");
      script.innerHTML = `(function () {
        return {
          event: {
            id: 2994775,
          },
          hidePreloading: true,
          display: "popup",
          popup: {
            triggerSelector: "#timepad_twf_register_2994775",
          },
        };
      })();`;
      document.body.appendChild(script);
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/detail/:id",
      element: <DetailPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
