import classNames from "classnames";
import ReportCard from "../ReportCard/ReportCard";
import ReportCardEmpty from "../ReportCard/ReportCardEmpty";
import { Box, Tab, Tabs } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";
import { mockedData, reportsData } from "../../data/mockData";
import { Report } from "../../types/report";
import { useState } from "react";
import "./styles.scss";

export type Data = {
  id?: string;
  time: string;
  label?: string;
  reports?: (number | null)[];
  className?: string;
  isSecondary?: boolean;
  isShort?: boolean;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="custom-tab-panel"
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Schedule = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const renderTab = (newValue: number) => {
    const getData = (reports: (number | null)[], isShort?: boolean) => {
      if (!reports[newValue] && !isShort) return <ReportCardEmpty />

      const reportId = isShort ? 0 : newValue
      const report = reportsData.find(
        (reportData) => reportData.id === reports[reportId]
      ) as Report;
      return <ReportCard {...report} isShort={isShort} />;
    };

    return (
      <div className="wrapper">
        {mockedData.map(({ label, time, reports, isSecondary, isShort }, index, arr) => (
          <div
            key={`report-${index}`}
            className={classNames("event", {
              "start-color": isSecondary,
              "main-color": !isSecondary,
              "with-cards": reports && reports.length,
            })}
          >
            <div className="time">{time}</div>
            {reports && reports.length ? (
              <div className="reports">{getData(reports, isShort)}</div>
            ) : (
              <div className="label">{label}</div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const emptyCards = () => {
    const count =
      reportsData.length % 3 === 0 ? 3 : 3 - (reportsData.length % 3);

    return Array.from({ length: count }, (_, index) => (
      <ReportCardEmpty key={`report-card-empty-${index}`} />
    ));
  };

  return (
    <div id="schedule" className="schedule">
      {false ? (
        <div className="first-stage">
          <span key="header-title" className="schedule-title">
            Расписание
          </span>
          {reportsData && reportsData.length ? (
            <div key="reports" className="reports">
              {reportsData.map((report, index) => (
                <ReportCard key={`report-card-${index}`} {...report} />
              ))}

              {emptyCards()}
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <BrowserView className="desktop-view">
            <div className="wrapper">
              <span className="schedule-title">Расписание</span>
              <div className="schedule-halls">
                <div className="hall-dummy" />
                <div className="halls-wrapper">
                  <div className="schedule-halls__hall">Малый зал</div>
                  <div className="schedule-halls__hall">Конференц-зал</div>
                  <div className="schedule-halls__hall">Кинозал «Луч»</div>
                </div>
              </div>
              {mockedData.map(({ label, time, reports, isShort, isSecondary }, index, arr) => (
                <div
                  key={`report-${index}`}
                  className={classNames("event", {
                    "start-color": isSecondary,
                    "main-color": !isSecondary,
                    "with-cards": reports && reports.length,
                  })}
                >
                  <div className="time">{time}</div>
                  {reports && reports.length ? (
                    <div className="reports">
                      {reports.map((reportId) => {
                        if (!reportId) return <ReportCardEmpty />
                        const report = reportsData.find(
                          (reportData) => reportData.id === reportId
                        ) as Report;
                        return (
                          <ReportCard key={`report-${reportId}`} {...report} isShort={isShort} />
                        );
                      })}
                    </div>
                  ) : (
                    <div className="label">{label}</div>
                  )}
                </div>
              ))}
            </div>
          </BrowserView>
          <MobileView className="mobile-view">
            <div className="wrapper">
              <span className="schedule-title">Расписание</span>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  variant="scrollable"
                  scrollButtons={false}
                  value={value}
                  onChange={handleChange}
                  sx={{ display: "grid" }}
                >
                  <Tab label="Малый зал" />
                  <Tab label="Конференц-зал" />
                  <Tab label="Кинозал «Луч»" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {renderTab(value)}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {renderTab(value)}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                {renderTab(value)}
              </CustomTabPanel>
            </div>
          </MobileView>
        </>
      )}
    </div>
  );
};

export default Schedule;
