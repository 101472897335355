import { Button, IconButton } from "@mui/material";
import { isMobile } from "react-device-detect";
import { ReactComponent as YappiLogo } from "../../assets/yappi-logo-dark.svg";
import { ReactComponent as BurgerIcon } from "../../assets/burger.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { HashLink, NavHashLink } from 'react-router-hash-link';
import "./styles.scss";

interface Props {
  show?: boolean;
}

const Menu = ({ show }: Props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onButtonClick = (positionId: string) => {
    navigate(`/#${positionId}`);
  };

  const onLogoClick = () => {
    navigate("#");
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getNavItems = () => {
    return (
      <div className="menu__items">
        <NavHashLink className="menu__items-item" to="/#schedule">
          Расписание
        </NavHashLink>
        <NavHashLink className="menu__items-item" to="/#ticket">
          Билеты
        </NavHashLink>
        <NavHashLink className="menu__items-item" to="/#organizers">
          Организаторы
        </NavHashLink>
        <NavHashLink className="menu__items-item" to="/#location">
          Расположение
        </NavHashLink>
        <NavHashLink className="menu__items-item" to="/#footer">
          Контакты
        </NavHashLink>
      </div>
    );
  };

  return (
    <div className="menu">
      <div className={`menu-wrapper ${show ? "active" : "hidden"}`}>
        <div className="menu__logo">
          <div>
            <Link reloadDocument to="/">
              <YappiLogo
                onClick={onLogoClick}
                style={{ cursor: "pointer" }}
                width={100}
                height={54}
              />
            </Link>
          </div>
          {!isMobile ? getNavItems() : null}
        </div>
        {!isMobile ? (
          <NavHashLink className="menu__button" to="/#ticket">
            <Button
              className="menu__button-buy"
            >
              Купить билет
            </Button>
          </NavHashLink>
        ) : (
          <div className="popover-wrapper">
            <BurgerIcon onClick={handleClick} />
            {open ? (
              <div className="menu__popover">
                <div className="menu__popover-header">
                  <YappiLogo width={100} height={54} />
                  <CloseIcon onClick={handleClose} />
                </div>
                <div className="menu__popover-list">{getNavItems()}</div>
                <HashLink className="menu__button" to="/#ticket">
                  <Button
                    className="menu__button-buy"
                  >
                    Купить билет
                  </Button>
                </HashLink>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Menu;
