import { ReactComponent as RaftLogo } from "../../assets/raft-logo.svg";
import { ReactComponent as PvsStudioLogo } from "../../assets/pvs-studio-logo.svg";
import { ReactComponent as HorizonLogo } from "../../assets/horizon.svg";
import { ReactComponent as CioCdoLogo } from "../../assets/ciocdo.svg";
import { ReactComponent as Inventorus } from "../../assets/logo-inventorus-color.svg";
import "./styles.scss";

const Partners = () => {
  return (
    <div className="partners">
      <div className="partners-wrapper">
        <div className="partners__container">
          <span className="partners__title">Генеральный партнер</span>
          <div className="partners__list">
            <div className="partners__logo">
              <a target="_blank" href="https://raftds.ru/" className="partners__link">
                <RaftLogo className="partners__icon" />
              </a>
            </div>
          </div>
          <span className="partners__title">Бронзовые партнеры</span>
          <div className="partners__list">
            <div className="partners__logo">
              <a target="_blank" href="https://pvs-studio.ru/ru/home/" className="partners__link--pvs">
                <PvsStudioLogo className="partners__icon" />
              </a>
            </div>
            <div className="partners__logo">
              <a target="_blank" href="https://www.krista.ru/" className="partners__link--pvs">
                <HorizonLogo className="partners__icon" />
              </a>
            </div>
          </div>
          <span className="partners__title">Информационные партнеры</span>
          <div className="partners__list">
            <div className="partners__logo">
              <a target="_blank" href="https://inventorus.ru/" className="partners__link--pvs">
                <Inventorus className="partners__icon" />
              </a>
            </div>
            <div className="partners__logo">
              <a target="_blank" href="https://ciocdo.ru/?utm_source=yappidays&utm_campaign=partner&utm_content=logo_site" className="partners__link--pvs">
                <CioCdoLogo className="partners__icon" />
              </a>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

export default Partners;
